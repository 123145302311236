var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-1"
  }, [_c('div-table', {
    attrs: {
      "config": _vm.configList,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "couriers": _vm.couriers,
      "limit": _vm.limit,
      "isFilterCourier": _vm.isFilterCourier,
      "statusShippChange": _vm.statusShippChange,
      "CourierInfo": _vm.CourierInfo,
      "tabId": _vm.tabId,
      "CheckQteDefecteuseexist": _vm.CheckQteDefecteuseexist,
      "CheckQteReturnedexist": _vm.CheckQteReturnedexist,
      "data": _vm.shippings,
      "element": _vm.element,
      "zones": _vm.zones,
      "typesShipp": _vm.typesShipp,
      "statusShipp": _vm.statusShipp,
      "loading": _vm.loading,
      "paginate": _vm.paginate,
      "CheckQteDefecteuseorderexist": _vm.CheckQteDefecteuseorderexist
    },
    on: {
      "Changelimit": _vm.Changelimit,
      "searchSellers": _vm.searchSellers,
      "searchCouriers": _vm.searchCouriers,
      "downloadShippings": _vm.downloadShippings,
      "emtyTabId": _vm.emtyTabId,
      "selectAllCheckbox": _vm.selectAllCheckbox,
      "getRowDetail": _vm.getRowDetail,
      "CheckQteDefecteuse": _vm.CheckQteDefecteuse,
      "CheckQteReturned": _vm.CheckQteReturned,
      "reset": _vm.reset,
      "refresh": _vm.refresh,
      "filtrer": _vm.filtrer,
      "search": _vm.search,
      "showProducts": _vm.showProducts,
      "CheckQteDefecteuseOrder": _vm.CheckQteDefecteuseOrder,
      "generateListPdf": _vm.generateListPdf,
      "paginatation": _vm.paginatation
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }